import { CrudFilters, HttpError, useLink, useMany, useExport, useApiUrl, useCreate, CrudFilter, useTranslate, LogicalFilter, BaseKey } from "@refinedev/core";
import React, { useCallback, useEffect, useState } from 'react'
import { Edit, EditButton, List, useModal, ImportButton, useImport, useTable, RefreshButton, ExportButton, useDrawerForm, Create, DeleteButton } from "@refinedev/antd";
import { Button, Card, Checkbox, Drawer, Form, Input, Progress, Select, Space, Table, Typography } from "antd";
import { ILabel, IUnit} from "interfaces";
import { JOB_RES_ID, JOB_TEXT_REWRITE, PRODUCT_ITEM_RES_ID, SPECIFICATION_LABEL_RES_ID, TENANT_RES_ID, UNIT_RES_ID } from "utility/constants";
import styled from '@emotion/styled'
import { useLocaleStore } from "stores/localeStore";
import { DownloadOutlined, FilterOutlined, HistoryOutlined, InfoCircleOutlined, MonitorOutlined, PlusOutlined, StopOutlined, SyncOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";
import { useTranslation } from "react-i18next";
import VbSelect from "components/common/vbSelector/vbSelect";
import UnitSelector from "components/selectors/unitSelector";
import Tooltip from "components/common/tooltip/tooltip";
import SpecStatsModal from "components/modals/specStats";
import { useTypesenseSync } from "components/hooks/syncTypesense";
import { Plus } from "lucide-react";

const TextSpan = styled.span`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-width: 300px;
`

const TableHeader = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  .header-buttons {
    display: flex;
    gap: 0.75rem;
  }
`

const TableTitle = styled(Typography.Title)`
  margin-bottom: 0.75rem !important;
`

const StyledCard = styled(Card)`
  .ant-page-header-heading-left {
    display: none;
  }

  .ant-page-header-heading {
    display: none;
  }
`

interface ISearch {
    name: string;
    customer: string;
    status: string;
}

export const LabelList: React.FC = () => {
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [globalFilters, setGlobalFilters] = useState<CrudFilter[]>([]);
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const localeState = useLocaleStore();
    const t = useTranslate();
    const { syncTypesenseProducts } = useTypesenseSync();

    const [specModalId, setSpecModalId] = useState<BaseKey | undefined>();

    const { formProps, drawerProps, show : showCreateLabelDrawer, saveButtonProps } = useDrawerForm<ILabel, HttpError, ILabel>({
      action: "create",
    });

    const { formProps : formPropsEdit, drawerProps: drawerPropsEdit, show: showEdit, saveButtonProps: saveButtonPropsEdit, id: editId } = useDrawerForm<ILabel, HttpError, ILabel>({
      action: "edit",
      warnWhenUnsavedChanges: true,
    });

    const { formProps: formPropsUnitCreate, drawerProps: drawerPropsUnitCreate, show : showCreateUnitDrawer, saveButtonProps: saveUnitButtonPropsCreate } = useDrawerForm<IUnit, HttpError, IUnit>({
      action: "create",
      resource: UNIT_RES_ID
    });
    
    const { tableProps, searchFormProps, setFilters } = useTable<ILabel, HttpError, ISearch>(
        {
            resource: SPECIFICATION_LABEL_RES_ID,
            liveMode: "auto",
            syncWithLocation: false,
            filters: {
              defaultBehavior: "replace",
            },
            meta: { headers: { "lang": "all" } }
        }
    );

    const handleSearch = (newValue : any) => {
      if(newValue == ""){
        setFilters([]);
        return;
      }

      setFilters(data => {
        const castedData = data as LogicalFilter[];
        const fullSearchIndex = castedData.findIndex((x) => x.field === "fullSearch")

        if (fullSearchIndex > -1) {
          castedData[fullSearchIndex].value = newValue;
          return castedData
        }

        return [...data, { field: "fullSearch", operator: "contains", value: newValue }];
      });
    };

    const pagination : any = tableProps?.pagination;

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };
  
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // const onImportModalFinish = async (data: any) => {
    //   closeImportModal();
    // }

    // const onTextRewriteFinish = async (data: any) => {
    //   close();
    //   mutate({
    //     resource: JOB_RES_ID,
    //     values: {
    //       Ids: selectedRowKeys,
    //       type: JOB_TEXT_REWRITE,
    //       context: data?.context
    //     },
    //     meta: { headers: { "lang": localeState.locale } }
    //   });
    //   setSelectedRowKeys([]);
    // }

    // const clearFilter = () => {
    //   setTableIsLoading(true);
    //   setFilters([]);
    //   setGlobalFilters([]);
    //   setTableIsLoading(false);
    // }
    
    // const closeFilter = (filters : any[]) => {
    //   setTableIsLoading(true);
    //   setFilterDrawerOpen(false);
    //   setFilters(filters);
    //   setTableIsLoading(false);
    // };

    const onChangeSelectUnits = (unitIdsArr: BaseKey[]) => {
      formPropsEdit.form?.setFieldValue("unitId", unitIdsArr[0])
    }

    formPropsEdit.initialValues = tableProps.dataSource?.find((item: any) => item.id === editId) ?? {};

    return (
      <StyledCard bordered={false} className="criclebox h-full">
        <TableTitle level={4}>Etiketter</TableTitle>
        <TableHeader>
          <Search placeholder="Sök etikett..." onSearch={handleSearch} />
          <div className="header-buttons">
            <Button
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "center",
              }}
              type="primary"
              icon={<Plus size={"1rem"}  />}
              onClick={() => showCreateLabelDrawer()}
            >
              <span style={{ marginTop: "2px" }}>Skapa ny etikett</span>
            </Button>{" "}

            <Button
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "center",
              }}
              type="primary"
              icon={<Plus size={"1rem"}  />}
              onClick={() => showCreateUnitDrawer()}
            >
              <span style={{ marginTop: "2px" }}>Skapa ny enhet</span>
            </Button>{" "}
          </div>
        </TableHeader>
        <List title={"Etiketter"} breadcrumb={false}>
          <Table
            {...tableProps}
            loading={tableIsLoading}
            rowKey="id"
            rowSelection={rowSelection}
            pagination={{ ...pagination, showSizeChanger: true }}
            footer={() => (
              <span>
                Totalt antal etiketter <strong>{pagination?.total}</strong>
              </span>
            )}
          >
            <Table.Column dataIndex="id" title="Id" />
            <Table.Column<ILabel>
              dataIndex="nameSv"
              title="Namn Svenska"
              render={(_, record) => <span>{record.names.sv}</span>}
            />
            <Table.Column<ILabel>
              dataIndex="nameNo"
              title="Namn Norska"
              render={(_, record) => <span>{record.names.no}</span>}
            />
            <Table.Column<ILabel>
              dataIndex="nameDa"
              title="Namn Danska"
              render={(_, record) => <span>{record.names.da}</span>}
            />
            {/* Add multiple unit columns based on language ? */}
            <Table.Column<ILabel>
              dataIndex="unitSymbol"
              title="Enhet"
              render={(_, record) => <span>{record.unit.unitSymbol}</span>}
            />
            <Table.Column<ILabel>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                    onClick={() => showEdit(record.id)}
                  />
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                   <Button type="primary" icon={<MonitorOutlined />} size="small" onClick={() => setSpecModalId(record.id)}/>
                   <Button type="primary" icon={<SyncOutlined />} size="small" onClick={() => syncTypesenseProducts([record.id])}/>
                </Space>
              )}
            />
          </Table>
        </List>

        <Drawer {...drawerPropsUnitCreate}>
          <Create
            saveButtonProps={saveUnitButtonPropsCreate}
            goBack={false}
            title="Skapa Enhet"
          >
            <Form {...formPropsUnitCreate} layout="vertical">
              <Form.Item
                label={"Förkortning"}
                name={"abbreviation"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={"Namn (SE,DK,NO)"}
                tooltip={{ title: 'Detta namn för enhet syns inte på hemsidan', icon: <InfoCircleOutlined style={{marginBottom: '3px', marginLeft: '5px', fontSize: '16px', color: "#195178"}} /> }}
                name={"name"}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Create>
        </Drawer>

        <Drawer {...drawerProps}>
          <Create
            saveButtonProps={saveButtonProps}
            goBack={false}
            title="Skapa Etikett"
          >
            <Form {...formProps} layout="vertical">
              {localeState.languages.map((language) => (
                <Form.Item
                  key={language.locale}
                  label={`Namn ${language.locale.toUpperCase()}`}
                  name={["names", language.locale]}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ))}
            </Form>
          </Create>
        </Drawer>

        <Drawer {...drawerPropsEdit}>
          <Edit
            saveButtonProps={saveButtonPropsEdit}
            recordItemId={editId}
            title="Redigera Etikett"
            headerButtons={<></>}
          >
            <Form {...formPropsEdit} layout="vertical">
              {/* <Form {...formPropsEdit} layout="vertical"> */}
              {localeState.languages.map((language) => (
                <Form.Item
                  key={language.locale}
                  label={`Namn ${language.locale.toUpperCase()}`}
                  name={["names", language.locale]}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              ))}
              <Form.Item
                label={"Välj enhet"}
                name="unitId"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <UnitSelector
                  selectedIds={[formPropsEdit?.initialValues?.unit?.unitId]}
                  onSelect={onChangeSelectUnits}
                ></UnitSelector>
              </Form.Item>
            </Form>
          </Edit>
        </Drawer>
        <SpecStatsModal specId={specModalId} onClose={() => setSpecModalId(undefined)}></SpecStatsModal>
      </StyledCard>
    );
};


/* <GenericSelector
  selectedId={"1"}
  requestSettings={{
    resource: "specificationLabels",
    pageParams: "?_end=10&_start=0",
    filters: "&filters=fullSearch_like=",
    optionLabel: "name",
    optionValue: "id",
    placeholder: "Välj etikett",
    initialSearch: true,
    multiSelect: false
  }}
/> */


/* <GenericSelector
selectedIds={["10017", "1013"]}
onChange={onChangeSelectUnits}
requestSettings={{
  resource: "products",
  pageParams: "?_end=10&_start=0",
  filters: "&filters=fullSearch_like=",
  optionLabel: "mainItemNo",
  optionValue: "mainItemNo",
  placeholder: "Välj produkt",
  initialSearch: true,
  multiSelect: true
}}
/> */