import { useEffect, useRef, useState } from 'react';
import { useOpenApiClient } from './base/useOpenApiClient';
import { ErrorResponse, GetManyRequestOptions } from 'interfaces/baseTypes';
import { components } from './base/schema';

type ProductBundleDto = components['schemas']['ProductBundleDto'];

const useProductBundleOpenApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<ErrorResponse | null>(null);
    const {client, getClient} = useOpenApiClient();
    const clientRef = useRef(client);

    useEffect(() => {
        clientRef.current = client;
    }, [client]);

    async function getProductBundles(_context?: unknown): Promise<ProductBundleDto[]> {
        // const { page = 0, pageSize = 10, order, orderByField, filters } = options;
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            
            if (!clientRef.current) {
                throw new Error('API client is not initialized');
            }
            
            const response = await clientRef.current.GET('/api/product-bundles');

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return [];
            }

            return response?.data as ProductBundleDto[];
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return [];
        } finally {
            setLoading(false);
        }
    }

    async function createProductBundle(itemNo: string): Promise<void> {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            
            if (!clientRef.current) {
                throw new Error('API client is not initialized');
            }
            
            const response = await clientRef.current.POST(`/api/product-bundles/sync/{articleNumber}`, {
                params: { path: { articleNumber: itemNo } },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    async function syncExistingProductBundle(id: number): Promise<void> {
        setLoading(true);
        setErrorMessage(null);
        try {
            if(!clientRef.current){
                clientRef.current = getClient();
            }
            
            if (!clientRef.current) {
                throw new Error('API client is not initialized');
            }

            const response = await clientRef.current.PATCH("/api/product-bundles/sync/{productBundleId}", {
                params: { path: { productBundleId: id } },
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const error = response?.error as unknown as ErrorResponse;
            if (error) {
                setErrorMessage(error);
                return undefined;
            }
        } catch (e: any) {
            setErrorMessage(e.message || 'An error occurred');
            return undefined;
        } finally {
            setLoading(false);
        }
    }

    return { getProductBundles, createProductBundle, syncExistingProductBundle, loading, errorMessage };
}

export default useProductBundleOpenApi; 