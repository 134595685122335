import React, { useState } from 'react';
import { Modal, Input, Alert } from 'antd';
import TextArea from 'antd/es/input/TextArea';

interface BundleProductFetchModalProps {
  onClose: () => void;
  isOpen: boolean;
  onFetch: (articleNumber: string) => void;
}

const BundleProductFetchModal: React.FC<BundleProductFetchModalProps> = ({
  onClose,
  isOpen,
  onFetch,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const validateAndSendRequest = () => {
    const regex = /^[\d-]+$/;
    const articleNumber = inputValue.trim();
    
    if (regex.test(articleNumber)) {
      onFetch(articleNumber);
      setIsError(false);
      setInputValue("");
      onClose();
    } else {
      setIsError(true);
    }
  };

  return (
    <Modal
      title="Hämta paketprodukt från Norce"
      open={isOpen}
      onCancel={onClose}
      okText="Hämta"
      onOk={validateAndSendRequest}
    >
      <span style={{ marginBottom: "1rem", display: "block" }}>
        Skriv in artikelnummer för att hämta paketprodukt från Norce.
      </span>
      {isError && (
        <Alert
          style={{ marginBottom: "0.5rem" }}
          message="Validering misslyckades. Enbart siffror och bindestreck är tillåtna."
          type="error"
          showIcon
        />
      )}
      <Input
        maxLength={20}
        onChange={(e) => setInputValue(e.target.value)}
        placeholder="Skriv in artikelnummer"
        style={{ marginBottom: '1rem' }}
        value={inputValue}
      />
    </Modal>
  );
};

export default BundleProductFetchModal; 