import { Modal, Form, Select, Typography, Checkbox } from "antd";
import { useState } from "react";

export const MultiTranslateTextModal: React.FC<any> = ({ modalProps, onFinish }) => {
  const [form] = Form.useForm();
  const [translateAll, setTranslateAll] = useState(false);

  const languageOptions = [
    { value: 'no', label: 'Norska' },
    { value: 'da', label: 'Danska' },
  ];

  const handleFinish = (values: any) => {
    const targetLanguages = translateAll 
      ? languageOptions.map(lang => lang.value)
      : Array.isArray(values.targetLanguages) 
        ? values.targetLanguages 
        : [values.targetLanguages];
    
    onFinish({ ...values, targetLanguages });
  };

  const handleTranslateAllChange = (checked: boolean) => {
    setTranslateAll(checked);
    if (checked) {
      form.setFieldsValue({ targetLanguages: languageOptions.map(lang => lang.value) });
    } else {
      form.setFieldsValue({ targetLanguages: [] });
    }
  };

  return (
    <Modal {...modalProps} width={600} onOk={() => form.submit()}>
      <h3>Välj språk att översätta till</h3>
      <Typography.Text type="secondary" style={{ display: 'block', marginBottom: '1.5rem' }}>
        Valda produkters beskrivningar och namn kommer att översättas till det valda språket. Befintliga översättningar kommer att skrivas över.
      </Typography.Text>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          targetLanguages: [],
        }}
        onFinish={handleFinish}
      >
        <Form.Item>
          <Checkbox 
            onChange={(e) => handleTranslateAllChange(e.target.checked)}
            checked={translateAll}
          >
            Översätt till alla språk
          </Checkbox>
        </Form.Item>
        <Form.Item 
          label="Målspråk" 
          name="targetLanguages"
          rules={[{ required: true, message: 'Välj minst ett målspråk' }]}
        >
          <Select
            mode="multiple"
            options={languageOptions}
            placeholder="Välj språk"
            disabled={translateAll}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}; 