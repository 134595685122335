import React, { useState } from "react";
import { Typography, Card, Row, Col, Space, Collapse, Image, Button, Spin, Tag } from "antd"
import { Box, Package, PenToolIcon as Tool, Cpu, Battery, Wrench, RefreshCw } from "lucide-react"
import { useQuery } from "@tanstack/react-query"
import BundleProductFetchModal from "components/modals/bundleProductFetch";
import useProductBundleOpenApi from "apiClients/useProductBundleOpenApiClient";
import FullScreenLoader from "components/common/loadIndicators/fullScreenLoader";

const { Title, Text, Paragraph } = Typography
const { Panel } = Collapse

export const BundleProductsList: React.FC = () => {
  const [fetchModalOpen, setFetchModalOpen] = useState(false);
  const { getProductBundles, createProductBundle, syncExistingProductBundle, loading } = useProductBundleOpenApi();

    const {
        data: packageProducts,
        isLoading,
        isError,
        error,
        refetch
    } = useQuery(['bundleProducts'], () => getProductBundles());

  const handleCreateBundleProduct = async (articleNumber: string) => {
    await createProductBundle(articleNumber);
    await refetch();
  };

  const handleSyncProductBundle = async (id: number) => {
    await syncExistingProductBundle(id);
    await refetch();
  }

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '48px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (isError) {
    return (
      <div style={{ padding: '24px' }}>
        <Text type="danger">Error loading bundle products: {(error as Error).message}</Text>
      </div>
    );
  }

  return (
    <>
      <div style={{ padding: "24px" }}>
        <div style={{ 
          display: "flex", 
          flexDirection: "column",
          marginBottom: "16px",
          backgroundColor: "#ffffff",
          padding: "16px",
          borderRadius: "8px",
          border: "1px solid #f0f0f0"
        }}>
          <div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0.5rem"
          }}>
            <Title level={4} style={{ margin: 0 }}>Paketprodukter</Title>
            <Button 
              type="primary"
              icon={<Box style={{ marginBottom: "-3px" }} size={16} />}
              onClick={() => setFetchModalOpen(true)}
            >
              Hämta ny paketprodukt
            </Button>
          </div>
          <Paragraph style={{ color: "#666", fontSize: "1.0rem", marginBottom: 0 }}>
            Här kan du se paketprodukter som finns i Norce. Inga redigeringar sker här utan det får man göra i Norce.
            <br />
            När man har ändrat i Norce så får man synka paketprodukten manuellt här.
          </Paragraph>
        </div>

        <Row gutter={[24, 24]}>
          {packageProducts?.map((item) => (
            <Col xs={24} sm={12} lg={8} key={item.id}>
              <Card
                style={{
                  height: "100%",
                  borderRadius: "8px",
                  border: "1px solid #f0f0f0",
                }}
                title={item.name}
                extra={
                  <Button 
                    type="text" 
                    icon={<RefreshCw size={16} />}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSyncProductBundle(item.id ?? 0);
                    }}
                  />
                }
              >
                <div style={{ marginBottom: "20px" }}>
                  <Image
                    src={item.imageUrl || "/placeholder.svg"}
                    alt={item.name}
                    width={96}
                    height={96}
                    preview={false}
                    style={{
                      objectFit: "contain",
                    }}
                  />
                </div>

                <Title level={5} style={{ marginBottom: "0.5rem", fontSize: "0.95rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    {item.itemNo}
                    <Tag color="blue">{Math.round(item.price).toLocaleString('sv-SE')} kr</Tag>
                </Title>

                <Collapse style={{ marginBottom: "1.0rem" }}>
                  <Panel 
                    header={
                      <div style={{ 
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        color: "rgba(0, 0, 0, 0.45)",
                        cursor: "pointer"
                      }}>
                        {item.description}
                      </div>
                    } 
                    key="1"
                  >
                    <Text type="secondary" style={{ whiteSpace: 'pre-line' }}>
                      {item.description}
                    </Text>
                  </Panel>
                </Collapse>

                <div style={{ marginTop: "auto" }}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginBottom: '12px' }}>
                    <Package size={20} style={{ marginBottom: "1px" }}/>
                    <Title level={5} style={{ margin: 0 }}>
                      Innehållande produkter:
                    </Title>
                  </div>
                  <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    {item.products.map((product) => (
                      <div
                        key={product.id}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                          padding: "8px",
                          border: "1px solid #f0f0f0",
                          borderRadius: "6px",
                          width: '100%'
                        }}
                      >
                        <Image
                          src={product?.images?.[0]?.url || "/placeholder.svg"}
                          alt={product?.name || ""}
                          width={48}
                          height={48}
                          preview={false}
                          style={{
                            objectFit: "contain",
                          }}
                        />
                        
                        <Text style={{fontWeight: "bold" }}>{product.mainItemNo + " - " + product.name}</Text>

                        {/* {
                          product.isPrebook && (
                            <Text style={{color: "#faad14"}}><Clock size={16} /> Förbokning</Text>
                          )
                        } */}
                      </div>
                    ))}
                  </Space>
                </div>
              </Card>
            </Col>
          ))}
        </Row>

        <BundleProductFetchModal
          isOpen={fetchModalOpen}
          onClose={() => setFetchModalOpen(false)}
          onFetch={handleCreateBundleProduct}
        />
      </div>
      <FullScreenLoader loading={loading} message="Uppdaterar paketprodukter" />
    </>
  )
};