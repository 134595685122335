export const USERS_RES_ID = "users";
export const TENANT_RES_ID = "tenants";
export const ROLE_RES_ID = "roles";
export const PRODUCT_ITEM_RES_ID = "productItems";
export const LOCALE_RES_ID = "locale";
export const SETTINGS_RES_ID = "settings";
export const JOB_RES_ID = "jobs";
export const FILE_RES_ID = "files";
export const RELATION_RES_ID = "relations";
export const RESOURCES_RES_ID = "resources";
export const PRODUCT_RES_ID = "products";
export const CATEGORY_RES_ID = "categories";
export const SPECIFICATION_LABEL_RES_ID ="specificationLabels" 
export const SPECIFICATION_RES_ID = "specifications"
export const VIDEO_RES_ID = "videos"
export const JOB_TEXT_REWRITE= "AiTextRewrite";
export const UNIT_RES_ID = "units";

export const PROMO_CARDS_RES_ID = "promoCards";
export const FEEDBACK_RES_ID = "feedback";
export const BUNDLE_PRODUCTS_RES_ID = "bundleProducts";
export const ECOMMERCE_RES_ID = "ecommerce";
export const PRICE_CHECK_RES_ID = "price-check";


// QUERY KEYS(React Query)
export const PRODUCTS_QUERY_KEY = "products";
export const WORK_TASKS_QUERY_KEY = "workTasks";
export const WORK_TASKS_PRODUCTS_QUERY_KEY = "workTasksProducts";

// STORAGE KEYS
export const DATA_GRID_COLUMN_VISIBILITY_STORAGE_KEY = "dataGrid-columnVisibility";