import { BaseKey, useApiUrl, useCustomMutation } from '@refinedev/core';
import { getUserInfoFromCache } from 'utility/helper';

export const useTranslate = () => {
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();
  const userInfo = getUserInfoFromCache();
  
  const translateText = (productIds: React.Key[], translationLocales: string[]) => {
    mutate({
      url: `${apiUrl}/actions/translate`,
      method: "post",
      successNotification: (data, values) => {
        return {
          message: "",
          description: "Översättningsjobb skapades",
          type: "success",
        };
      },
      values: {
        ids: productIds,
        context: "",
        translationLocales: translationLocales
      },
      config: {
        headers: { Authorization: `Bearer ${userInfo.token}`}
      }
    });
  };

  return { translateText };
}; 